import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "rc-slider";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "rc-slider/assets/index.css";

import { getPrice } from "./MonthlyPlans";

const DISCOUNT = 0;
export function OneTimePayment() {
  const { t } = useTranslation();

  const handlePurchase = async (sku) => {
    console.log({ sku });
    // const { id, name, object } = sku;
    // eslint-disable-next-line no-restricted-globals
    open("https://starter.trato.io/plans/one-time");
    // await stripe.redirectToCheckout({
    //   successUrl: `${config.baseUrl}/checkout/success?productId=${id}&productName=${encodeURIComponent(name)}`,
    //   cancelUrl: `${config.baseUrl}/plans/one-time?currency=${currency}`,
    //   items: [
    //       {
    //           [object]: id,
    //           quantity: 1
    //       }
    //   ],
    //   clientReferenceId: profile.organization,
    //   customerEmail: profile.email,
    //   // locale: 'mx'
    // })
  };

  return (
    <Container class="py-4 mb-4">
      <Row className="mt-4 justify-content-center">
        <Col className="mt-2" sm={5}>
          <ShieldItemContainer
            name={t("packages.contracts_level_1.title")}
            benefits={Object.values(
              t("packages.contracts_level_1.benefits", { returnObjects: true })
            )}
            productId="contracts_level_1"
            discount={DISCOUNT}
            // isLoading={isLoading}
            // currentStorageSelected={currentStorageSelected}
            onPurchase={handlePurchase}
            // onCurrentStorageSelectedChange={handleCurrentSelectedChange}
            id="PAK1"
          />
        </Col>
        <Col className="mt-2" sm={5}>
          <ShieldItemContainer
            name={t("packages.contracts_level_2.title")}
            benefits={Object.values(
              t("packages.contracts_level_2.benefits", { returnObjects: true })
            )}
            productId="contracts_level_2"
            discount={DISCOUNT}
            // isLoading={isLoading}
            // currentStorageSelected={currentStorageSelected}
            onPurchase={handlePurchase}
            // onCurrentStorageSelectedChange={handleCurrentSelectedChange}
            id="PAK2"
          />
        </Col>
      </Row>
    </Container>
  );
}

function getPackageQuantity(currentPackage = {}) {
  return currentPackage?.id?.split?.("_")[0];
}

function ShieldItemContainer(props) {
  const {
    name,
    currency = "mxn",
    productId,
    benefits = [],
    type = "good",
    currentStorageSelected = {},
    onPurchase,
    discount,
    id,
  } = props;
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPackage, setCurrentPackage] = useState({});

  const fetchPackages = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/integrations/stripe/products/${productId}/packages?currency=${currency}&type=${type}`
    );
    const json = await response.json();
    const sortedData = json?.data
      ?.filter((p) => !p.id.includes("1_contracts_level"))
      .sort((a, b) => a.attributes?.index - b?.attributes?.index);
    const totalPackages = sortedData.length - 1;
    const valuePerPackage = 100 / totalPackages;
    const marks = sortedData.reduce((obj, item, i) => {
      obj[Math.round(i * valuePerPackage)] = item;
      return obj;
    }, {});

    setData({
      min: getPackageQuantity(sortedData[0]),
      marks,
      max: getPackageQuantity(sortedData[sortedData.length - 1]),
    });
    setCurrentPackage(sortedData[0]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handlePurchase = () => {
    onPurchase(currentPackage);
  };

  const handleChange = (value) => {
    console.log(value, data.marks[value]);
    setCurrentPackage(data.marks[value]);
  };

  return (
    <div className="rounded-md bg-white d-flex flex-column justify-content-between p-4 flex-auto">
      <div className="">
        <div className="pb-3 border-bottom">
          <div className="font-weight-bold font-size-32">{name}</div>
          <div
            className="font-weight-bold font-size-42 py-2 mt-4"
            hidden={isLoading}
          >
            {!!discount && (
              <div className="d-flex align-items-center">
                <span
                  className={clsx(discount && "text-muted text-strikethrough")}
                >
                  {getPrice(
                    discount
                      ? (currentPackage?.price * 100) / discount
                      : currentPackage?.price,
                    currentPackage?.currency,
                    true
                  )}
                </span>{" "}
                <Badge
                  className="ml-2"
                  color="secondary"
                  size="xl"
                  hidden={!discount || isLoading}
                >
                  {discount}%
                </Badge>
              </div>
            )}
            <span>
              {getPrice(currentPackage?.price, currentPackage?.currency, true)}{" "}
            </span>
            <span className="text-muted font-size-18 ml-2">
              {getPackageQuantity(currentPackage)} Firmas
            </span>
          </div>
          <div className="font-size-14 text-accent" hidden={!discount}>
            Valido hasta el 15 de octubre del 2022
          </div>
          <div className="text-center  mt-4" hidden={!isLoading}>
            <Spinner />
          </div>
        </div>
        <div className="">
          <Row>
            {benefits.map((benefit) => (
              <div className="d-flex py-2 px-4 mt-3" key={benefit}>
                <div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-primary"
                    size="lg"
                  />
                </div>
                <div className="ml-2 text-muted">{benefit}</div>
              </div>
            ))}
          </Row>
        </div>
      </div>
      <div className="w-100 mt-4">
        <div className="d-flex my-2 mb-4" hidden={isLoading}>
          <div className="mr-3">{data.min}</div>
          <div className="w-100 p-2">
            <Slider
              min={0}
              defaultValue={0}
              marks={data.marks}
              step={null}
              onChange={handleChange}
              trackStyle={{ background: "#f47b33" }}
              activeDotStyle={{ background: "#f47b33", borderColor: "#f47b33" }}
              handleStyle={{
                background: "#f47b33",
                borderColor: "#f47b33",
                boxShadow: "0 0 5px #f47b33",
              }}
            />
          </div>
          <div className="ml-3">{data.max}</div>
        </div>
        <Button
          className="w-100 rounded-md  mt-4 font-size-20"
          color="primary"
          disabled={isLoading}
          onClick={handlePurchase}
          id={id}
        >
          Comprar
        </Button>
      </div>
    </div>
  );
}
