import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  faEnvelope,
  faGlobeAmericas,
  faCheckDouble,
  faHandshake,
  faDatabase,
  faUserShield,
  faGavel,
  faFileSignature,
  faLock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Switch from "react-input-switch";
import Blog01 from "../images/blog/blog.jpg";
import Layout from "../layouts/index";
import BlogArticle from "../components/Blog";
import Clients from "../components/Clients";
import ProductCLM1 from "../images/product-clm-1.png";
import ProductCLM2 from "../images/product-clm-2.png";
import DemoForm from "../components/DemoForm";
import { MonthlyPlans } from "../components/MonthlyPlans";
import { OneTimePayment } from "../components/OneTimePayment";
import DashboardWaves from "../images/dashboard-starter-waves-es.svg";
import StarterHeroImage from "../images/BG_Patron.png";
import Dashboard from "../images/500-startups/dashboard-starter.svg";
import Dashboard2 from "../images/500-startups/dashboard-starter-2.svg";
import MujerStarter from "../images/mujer-starter.jpg";
import PagosIcon from "../images/pagos.svg";
import ComercialesIcon from "../images/comerciales.svg";
import LaboralesIcon from "../images/laborales.svg";
import InvsersionIcon from "../images/inversion.svg";
import EnvioIcon from "../images/envio.svg";
import BlindajeIcon from "../images/blindaje.svg";
import CategoriesLayout01 from "../images/categories-Patron1.svg";
import CategoriesLayout02 from "../images/categories-Patron2.svg";
import SmartPhone from "../images/Phone.png";
import PatronPymeFoto from "../images/Patron_PYME_Foto@2x.png";
import enPhone from "../images/newIndex/page2/Phone.en.webp";
import esPhone from "../images/newIndex/page2/Phone.es.webp";
function GetStartedButton({ className, label = "page1.button", id = "" }) {
  const { t } = useTranslation(["starter"]);

  return (
    <a
      href="https://starter.trato.io/signup"
      target="_blank"
      rel="noopener noreferrer"
      id={id}
      className={classNames(
        "btn btn-primary rounded-md px-7 py-1 font-size-24",
        className
      )}
      onClick={(e) => {
        trackCustomEvent({
          category: "Starter - Trato Starter",
          action: "Crea tu cuenta gratis en sección Hero",
        });
      }}
    >
      {t(label)}
    </a>
  );
}

function TratoStarter({ pageContext: { locale }, location }) {
  const { t } = useTranslation(["starter"]);
  const [planType, setPlanType] = useState("one-time");
  return (
    <Layout
      id="landing"
      locale={locale}
      bodyClass="page-home landing bg-grey overflow-hidden"
      headerClassName="bg-blue text-white"
      languageSwitcherClassName="text-white"
      border
      location={location}
      simpleLayout
      scrollLogoVersion={2}
      navType="starter"
    >
      <section className="bg-light-yellow">
        <div className="container mt-10 pt-5">
          <div className="px-1 mx-auto text-center">
            <h1 className="font-weight-bold mb-2" style={{ fontSize: 80 }}>
              {t("page1.title")}
            </h1>
            <h3 className="lead text-muted mb-0 font-size-32">
              {t("page1.subtitle")}
            </h3>
            <GetStartedButton className="mt-6" id="CREARCUENTA1" />
          </div>
          <img src={StarterHeroImage} className="img-fluid w-100 mt-6" alt="" />
        </div>
      </section>

      <section className="bg-blue py-12 position-relative">
        <img
          className="position-absolute d-none d-sm-block"
          src={CategoriesLayout01}
          style={{ top: 0, right: 0 }}
          alt=""
        />
        <img
          className="position-absolute d-none d-sm-block"
          src={CategoriesLayout02}
          style={{ bottom: 0 }}
          alt=""
        />
        <div className="container " style={{ zIndex: 1000 }}>
          <Row>
            <Col md={6}>
              <Row className="">
                <Col className="mt-5" md="6">
                  <Category
                    icon={LaboralesIcon}
                    name={t("page2.boxes.0.title")}
                    description={t("page2.boxes.0.text")}
                  />
                </Col>
                <Col className="mt-5" md="6">
                  <Category
                    icon={PagosIcon}
                    name={t("page2.boxes.1.title")}
                    description={t("page2.boxes.1.text")}
                  />
                </Col>
                <Col className="mt-5" md="6">
                  <Category
                    icon={ComercialesIcon}
                    name={t("page2.boxes.2.title")}
                    description={t("page2.boxes.2.text")}
                  />
                </Col>
                <Col className="mt-5" md="6">
                  <Category
                    icon={InvsersionIcon}
                    name={t("page2.boxes.3.title")}
                    description={t("page2.boxes.3.text")}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6} className="px-4">
              <div className="ml-5 d-flex flex-column justify-content-around p-2 h-100">
                <h2 className="font-weight-bold text-white font-size-48">
                  {t("page2.title")}
                </h2>
                <p className="lead text-white font-size-24">
                  {t("page2.text")}
                </p>
                <div>
                  <GetStartedButton id="CREARCUENTA2" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="bg-light py-12 position-relative">
        <div className="container ">
          <Row>
            <Col md={6}>
              <div className="d-flex flex-column justify-content-center h-100">
                <h3 className="font-weight-bold font-size-48">
                  {t("page3.title")}
                </h3>
                <p className="text-muted font-size-24 mt-6">
                  {t("page3.text")}
                </p>
                <div className="mt-6">
                  <SignatureBenefit
                    icon={BlindajeIcon}
                    name={t("page3.boxes.0.title")}
                    description={t("page3.boxes.0.text")}
                  />
                  <SignatureBenefit
                    icon={EnvioIcon}
                    name={t("page3.boxes.1.title")}
                    description={t("page3.boxes.1.text")}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mx-auto d-flex justify-content-center p-2">
                <img
                  src={locale === "es" ? esPhone : enPhone}
                  className="w-100"
                  alt={locale}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="bg-primary py-12 position-relative">
        <div className="container-fluid p-0">
          <Row>
            <Col md={6}>
              <div className="mx-auto d-flex justify-content-center ">
                <img src={PatronPymeFoto} className="w-100" alt="" />
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex flex-column  h-100 p-2">
                <h2
                  className="text-white mb-5 font-weight-bold d-none d-sm-block mt-7"
                  style={{ fontSize: 60 }}
                >
                  {t("page4.title")}
                </h2>
                <h2
                  className="text-white mb-5 font-weight-bold d-block d-sm-none"
                  style={{ fontSize: 40 }}
                >
                  {t("page4.title")}
                </h2>
                <p className="text-white w-75 my-2 font-size-20">
                  {t("page4.text")}
                </p>
                <div>
                  <GetStartedButton
                    className="bg-blue mt-6"
                    label="Crea una Cuenta"
                    id="CREARCUENTA3"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="bg-grey py-12">
        <div className="container ">
          <div className="mx-auto">
            <h2
              className="font-weight-bold text-center"
              style={{ fontSize: 60 }}
            >
              {t("page5.title")}
            </h2>
            <h3 className="lead text-muted text-center font-size-20 mt-4">
              {t("page5.text")}
            </h3>
            <div className="d-flex justify-content-center mt-5">
              <span className="mr-2 font-weight-bold ">
                {t("page5.text2.0")}
              </span>
              <div id="PAK-SUB">
                <Switch
                  on="suscriptions"
                  off="one-time"
                  value={planType}
                  onChange={setPlanType}
                  styles={{
                    container: {
                      height: 20,
                      width: 30,
                    },
                    trackChecked: {
                      backgroundColor: "#f47b33",
                    },
                  }}
                />
              </div>
              <span className="ml-2 font-weight-bold">
                {t("page5.text2.1")}
              </span>
            </div>

            <div className="mt-5" hidden={planType === "one-time"}>
              <MonthlyPlans />
            </div>
            <div className="mt-5" hidden={planType === "suscriptions"}>
              <OneTimePayment />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default TratoStarter;

function Category({ name, description, icon }) {
  return (
    <div className="bg-light rounded-md p-5 h-100">
      <div className="py-1">
        <img src={icon} alt="" width={50} height={50} />
      </div>
      <div>
        <h3 className="font-weight-bold font-size-26">{name}</h3>
        <p className="font-size-18 text-muted">{description}</p>
      </div>
    </div>
  );
}

function SignatureBenefit({ name, description, icon }) {
  return (
    <div className="d-flex align-items-baseline bg-light rounded p-2">
      <div className="py-1">
        <img src={icon} alt="" width={50} height={50} />
      </div>
      <div className="p-2 ml-3">
        <h4 className="font-weight-bold">{name}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
