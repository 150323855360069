import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export function getPrice(price, currency, withCurrency) {
  if (!price) return "";
  switch (currency) {
    case "mxn":
    case "usd":
      return `$${(price / 100).toFixed(2)} ${
        withCurrency ? currency?.toUpperCase() : ""
      }`;
    default:
      return `$${(price / 100).toFixed(2)} ${currency?.toUpperCase()}`;
  }
}

const benefits = {
  free: [
    "Plantillas gratuitas desarrolladas pon nuestros expertos",
    "25MB de almacenamiento ",
    "Blindaje de Firmas Electrónicas® Nivel 1 - 10",
  ],
  basic: [
    "Todo lo del plan gratuito más",
    "Gestión básica de envío",
    "50GB de almacenamiento",
    "Blindaje de Firmas Electrónicas® Nivel 1 - 10 al mes",
    "Blindaje de Firmas Electrónicas® Nivel 2 - 5 al mes",
  ],
  premium: [
    "Todo lo del plan básico más",
    "Edición ilimitada de elementos",
    "1 plantilla premium al mes gratis",
    "100GB de almacenamiento",
    "Blindaje de Firmas Electrónicas® Nivel 1 - 50 al mes",
    "Blindaje de Firmas Electrónicas® Nivel 2 - 10 al mes",
  ],
};

const meta = {
  productId: "starter_plan",
  type: "service",
  currency: "mxn",
};

const DISCOUNT = 0;

export function MonthlyPlans() {
  // const stripe = useStripe();
  const currency = "mxn";
  const [plans, setPlans] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const fetchPackages = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.GATSBY_API_URL}/integrations/stripe/products/${meta.productId}/packages?currency=${meta.currency}&type=${meta.type}`
    );
    const json = await response.json();
    setPlans({
      basic: json.data.find((d) => d.id.includes("basic_starter_plan_mx")),
      premium: json.data.find((d) => d.id.includes("premium_starter_plan_mx")),
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handlePurchase = async (plan) => {
    // eslint-disable-next-line no-restricted-globals
    open("https://starter.trato.io/plans/monthly");
    // console.log({plan})
    // const { id, name, object } = plan.stripeInfo;
    // if (plan.type === 'free') {
    //   changePlanMutate({type: 'free'})
    // } else {
    //   await stripe.redirectToCheckout({
    //     successUrl: `${config.baseUrl}/checkout/success?productId=${id}&productName=${encodeURIComponent(name)}`,
    //     cancelUrl: `${config.baseUrl}/plans/monthly?currency=${currency}`,
    //     items: [
    //         {
    //             [object]: id,
    //             quantity: 1
    //         }
    //     ],
    //     clientReferenceId: profile.organization,
    //     customerEmail: profile.email,
    //     // locale: 'mx'
    //   })
    // };
  };

  return (
    <Container class="py-4 mb-4">
      <Row className="mt-4 justify-content-center">
        <Col className="mt-2" sm={5}>
          <PlanItem
            type="basic"
            name="Plan Basic"
            price={getPrice(plans.basic?.amount, plans.basic?.currency, true)}
            originalPrice={getPrice(
              (plans.basic?.amount * 100) / DISCOUNT + 100,
              plans.premium?.currency,
              true
            )}
            discount={DISCOUNT}
            stripeInfo={plans.basic}
            isLoading={isLoading}
            // currency="mxn"
            benefits={benefits.basic}
            onPurchase={handlePurchase}
            id={"SUB1"}
          />
        </Col>
        <Col className="mt-2" sm={5}>
          <PlanItem
            type="premium"
            name="Plan Premium"
            price={getPrice(
              plans.premium?.amount,
              plans.premium?.currency,
              true
            )}
            originalPrice={getPrice(
              (plans.premium?.amount * 100) / DISCOUNT + 100,
              plans.premium?.currency,
              true
            )}
            discount={DISCOUNT}
            isLoading={isLoading}
            stripeInfo={plans.premium}
            // currency="mxn"
            benefits={benefits.premium}
            onPurchase={handlePurchase}
            id={"SUB2"}
          />
        </Col>
      </Row>
    </Container>
  );
}

function PlanItem(props) {
  // eslint-disable-next-line no-shadow
  const {
    type,
    name,
    price,
    currency,
    benefits = [],
    isCurrent,
    isLoading,
    onPurchase,
    discount,
    originalPrice,
    id,
  } = props;

  const handlePurchase = () => {
    onPurchase(props);
  };

  return (
    <div className="rounded-md bg-white d-flex flex-column justify-content-between p-4 flex-auto h-100">
      <div>
        <div className="border-bottom pb-3">
          <div
            className="font-weight-bold font-size-32 mb-4"
            style={{ fontSize: 32 }}
          >
            {name}
          </div>
          {/* <div className="font-weight-bold font-size-42 mt-4" hidden={isLoading}>{price} <span className="text-uppercase">{currency}</span></div> */}
          <div className="d-flex align-items-center">
            <PriceLabel
              discount={discount}
              price={discount ? originalPrice : price}
              currency={currency}
              hidden={isLoading}
            />
            <Badge
              className="ml-2"
              color="secondary"
              size="xl"
              hidden={!discount || isLoading}
            >
              {discount}%
            </Badge>
          </div>
          <PriceLabel
            price={price}
            currency={currency}
            hidden={isLoading || !discount}
          />

          <div className="text-center mt-4" hidden={!isLoading}>
            <Spinner />
          </div>
          {/* <div className="font-size-14 text-accent" hidden={!discount}>
                Valido hasta el 15 de octubre del 2022
          </div> */}
        </div>
        <div className="mt-4">
          {benefits.map((benefit) => (
            <div className="d-flex py-2 px-4 mt-3" key={benefit}>
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary"
                  size="lg"
                />
              </div>
              <div className="ml-2 text-muted">{benefit}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-100m mt-4" hidden={isLoading}>
        <Button
          className="w-100 rounded-md font-size-20"
          color={isCurrent ? "accent" : "primary"}
          disabled={isCurrent}
          onClick={handlePurchase}
          id={id}
        >
          {isCurrent
            ? "Actual"
            : type === "free"
            ? "Cambiar Suscripcion"
            : "Comprar"}
        </Button>
      </div>
    </div>
  );
}
function PriceLabel({ hidden, price, currency, discount }) {
  return (
    <div
      className={clsx(
        "font-weight-bold",
        discount ? "text-strikethrough text-muted font-size-24" : "font-size-42"
      )}
      hidden={hidden}
    >
      {price} <span className="text-uppercase">{currency}</span>
    </div>
  );
}
